import React, { useState } from 'react';
import { Button, Col, Row, Select, Typography, Icon, Tooltip, Modal } from "antd";
import { useTranslation } from 'react-i18next';
import CampaignGroupFilterHolder from "./Styles/campaignGroupFilterStyle";
const { Text } = Typography;

const ScreensGroupFilterComponent = (props) => {
  const { t } = useTranslation();
  const {
    managerDisplayFilter,
    managerDisplaySelector,
    setManagerDisplaySelector,
    setManagerDisplayFilter,
    disabledManagerDisplayFilter,
    disableManagerDisplayFilter,
    isSuperUser,
    customContents,
    cart,
    realAmount,
    updateCustomContents,
    isPaymentStatusApproved,
    programmatic
  } = props;

  const arrFilter = [
    {
      name: "aspect",
      label: "Aspect ratio",
    },
    {
      name: "dimensions",
      label: "General dimensions",
    },
    {
      name: "resolution",
      label: "Resolution",
    },
    {
      name: "screen",
      label: "Type of screen",
    },
    {
      name: "subtype",
      label: "Sub Type of screen",
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onClick = (filter) => {
    if (filter.name === managerDisplayFilter) return;
    setManagerDisplayFilter(filter.name);
    disableManagerDisplayFilter(true);
  }

  // Only for pending campaign
  const redistributeImpressionsEquitably = () => {

    const listCostDisplay = cart.reduce((acc, display) => {
      acc[display.id] = display.smart_campaign_cpm / 1000;
      return acc;
    }, {});

    const totalCostPerImpression = customContents.reduce((acc, customContent) => {
        return acc + (listCostDisplay[customContent.displayId] || 0);
    }, 0);

    let totalImpressions = Math.floor(realAmount / totalCostPerImpression);
    let assignedBudget = 0;
    let customContentsAux = customContents.map((customContent, index) => {
        assignedBudget = assignedBudget + totalImpressions;
        return { ...customContent, promised_shows: totalImpressions};
    });

    let remainingBudget = realAmount - assignedBudget;

    for (let i = 0; i < customContentsAux.length && remainingBudget > 0; i++) {
        const extraCost = customContentsAux[i].smart_campaign_cpm;
        if (remainingBudget >= extraCost) {
            customContentsAux[i].promised_shows += 1;
            remainingBudget = remainingBudget - extraCost;
        }
    }

    updateCustomContents(customContentsAux)
  }

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = () => {
    redistributeImpressionsEquitably();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CampaignGroupFilterHolder>
      <Row
        type="flex"
        align="top"
        justify="space-between"
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='groupFilter'>
          <div
            className="contentGroupFilter"
            type="flex"
            align="middle">
            <Text style={{marginRight: "5px"}} >{t("Group by")}:</Text>
            {arrFilter.map((filter) => (
              <Button
                key={filter.name}
                className='groupFilterButton'
                type={managerDisplayFilter === filter.name ? "primary" : ""}
                disabled={disabledManagerDisplayFilter}
                onClick={() => onClick(filter)}
              >
                {t(filter.label)}
              </Button>
            ))}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            className="contentGroupFilter"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Text>{t("Distributed by")}:</Text>
              <Select
                value={managerDisplaySelector}
                onChange={setManagerDisplaySelector}
                style={{ width: 150, marginLeft: "10px" }}
              >
                <Select.Option key={"nothing"} value={""}>
                  {t("None")}
                </Select.Option>
                <Select.Option key={"country"} value={"country"}>
                  {t("Countries")}
                </Select.Option>
                {isSuperUser && (
                  <Select.Option key={"company"} value={"company"}>
                    {"Media owners"}
                  </Select.Option>
                )}
              </Select>
            </div>
            {programmatic === "programmatic" && !isPaymentStatusApproved && (
              <div>
                <Button style={{ marginLeft: "20px" }} onClick={showModal}>
                  {t("Balancear impressions")}
                </Button>
                <Tooltip
                  placement="left"
                  title={t("Equalize the spots across all screens, in the exact amount that keeps the campaign is budget up to date")}
                >
                  <Icon style={{ marginLeft: "10px" }} className="cardAudienceIconInfo" type="info-circle" theme="outlined" />
                </Tooltip>
                <Modal
                  title={t('Confirm redistribution')}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={t('OK')}
                  cancelText={t('Cancel')}
                >
                  <p style={{margin: "20px",fontSize: "16px"}}>{t("You're about to equalize the spots across all screens, which will modify any previous spot distribution. Do you wish to continue?")}</p>
                </Modal>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </CampaignGroupFilterHolder>

  );
};

export default ScreensGroupFilterComponent;

export const TRANSLATIONS_ES = {
    "Welcome to React": "ES",
    "Language": "Idioma",

    //Language
    "es": "Español",
    "es_ar": "Español (Argentina)",
    "en": "English",
    "Search": "Buscar",
    "language iso 639-1": "ES",

    // Top bar component
    "I offered your screens": "Ofrece tus pantallas",
    "Screen manager": "CMS",
    "My campaigns": "Mis campañas",
    "Go to CMS" : "Ir al CMS",
    "My profile": "Mi perfil",
    "Sign off": "Cerrar sesión",
    "Sign up": "Regístrate",
    "Sign up as an agency": "Regístrate como agencia",
    "Sign up as an advertiser": "Regístrate como anunciante",
    "Log in": "Inicia sesión",
    "Login": "Ingresa",
    "Agency Account": "Cuenta Agencia",
    "My quotes": "Mis cotizaciones",
    "Password": "Contraseña",

    "Code2FA": "Código de seguridad",
    "I forgot my password": "Olvidé mi contraseña",
    "Dont have an account?": "¿No tienes una cuenta?",
    "Do you already have your account?": "¿Ya tienes tu cuenta?",
    "Are you a media owner?": "¿Eres dueño de medios?",
    "Register here": "Regístrate aquí",
    "Create company account": "Crear cuenta empresa",
    "Recover your password": "Recupera tu contraseña",
    "Enter your email and we will send you the instructions to reset your password.": "Ingresa tu correo y te enviaremos las instrucciones para resetear tu contraseña.",
    "Send instructions": "Enviar instrucciones",
    "Resend verification email":"Reenviar correo de verificación",
    "Enter your email and we will resend you the verification instructions.":"Ingresa tu correo y te enviaremos las instrucciones para verificar tu cuenta.",
    "Resend email":"Reenviar correo",
    "Company": "Empresa",
    "Name": "Nombre",
    "First Name": "Nombre",
    "Last name": "Apellido",
    "Phone": "Teléfono",
    "Data entered correctly, we will send you an email when we confirm your data.": "Datos ingresados correctamente, te enviaremos un mail cuando confirmemos tus datos.",
    "Session closed successfully": "Sesión cerrada correctamente",
    "Welcome": "Bienvenido",
    "Error sending email": "Error al enviar el email",
    "Selected screens must have the same currency": "Las pantallas seleccionadas deben tener la misma moneda",
    "Good! An email was sent to your mailbox to retrieve your password.": "¡Bien! Se envió un email a tu casilla para recuperar tu contraseña.",
    "Good! An email was sent to your mailbox to activate yout account.": "¡Bien! Se envió un email a tu casilla para activar tu cuenta.",
    "Good! Just one step left, activate your account.": "¡Bien! Solo falta un paso, activa tu cuenta",
    "Good! You just need to verify your email": "¡Bien! Solo falta que verifiques tu email",
    "To begin you must verify your email. Check your email box to complete the registration process. Remember to check your spam folder.": "Para comenzar debes verificar tu email. Revisa tu casilla de correo para completar el proceso de registro. Recuerda revisar la carpeta de correo no deseado.",
    "In order to use your account you have to activate it. Check your email address to finish the registration process." : "Para usar tu cuenta debes activarla. Consulte su casilla de correo para finalizar el proceso de registro. Recuerda revisar la carpeta de correo no deseado.",
    "Url whatsapp, Hello, I would like to have advice publinet": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20me%20gustaría%20tener%20asesoramiento.%20",
    "Url whatsapp, Hello, I would like to have advice latinad": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20me%20gustaría%20tener%20asesoramiento.%20",
    "Url whatsapp, Hello, I would like to have advice fenix": "https://api.whatsapp.com/send?phone=14073752728&text=Hi,%20I%20would%20like%20to%20have%20advice.",
    "Url whatsapp, Hello, I would like to have advice mediashake": "https://api.whatsapp.com/send?phone=573107964234&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20MediaShake...",
    "Url whatsapp, Hello, I would like to have advice duoprint": "https://api.whatsapp.com/send?phone=593999560300&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20DuoPrint...",
    "Url whatsapp, Hello, I would like to have advice hak media": "https://api.whatsapp.com/send?phone=56935574177&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20%20Hakmedia...",
    "Currency": "Moneda",
    "Terms": "Términos y condiciones",
    "Content Manager System": "Gestor de contenido",
    "Screen Player": "Reproductor de pantallas",
    "Supply Side Platform": "Plataforma de ventas online",
    "Audience measurement": "Medición de audiencia",
    "Static OOH platform": "Plataforma de estáticos OOH",

    // Footer
    "You can start creating your campaign by entering the zone and date.": "Puede comenzar a crear su campaña colocando zona y fecha.",
    "Advertisers": "Anunciantes",
    "Create campaign": "Crear campaña",
    "I am an agency": "Soy una Agencia",
    "Purchase advice": "Asesoramiento en compra",

    "Screen owners": "Dueños de pantalla",
    "Offer your screens": "Ofrece tus pantallas",
    "SSP": "SSP",
    "Product": "Producto",
    "Certifications": "Certificaciones",
    "Community": "Comunidad",
    "Success cases": "Casos de éxito",
    "Plan your campaign": "Planifica tu campaña",
    "Technological solutions for the DOOH industry": "Soluciones tecnológicas para la industria del DOOH",

    "Contact": "Contacto",
    "Write us by whatsapp": "Escríbenos por whatsapp",
    "Speak with us": "Habla con nosotros",
    "Work with us": "Trabaja con nosotros",
    "Help": "Ayuda",

    // TODO: not implemented
    "Hello, I would like to work with you. I leave information about me:": "Hola, me gustaría trabajar con ustedes. Les dejo información sobre mi:",

    "Url whatsapp, I am an agency publinet": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20tengo%20una%20agencia%20y%20me%20gustar%C3%ADa%20poder%20operar%20a%20trav%C3%A9s%20de%20la%20plataforma.",
    "Url whatsapp, I am an agency latinad": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20tengo%20una%20agencia%20y%20me%20gustar%C3%ADa%20poder%20operar%20a%20trav%C3%A9s%20de%20la%20plataforma.",

    "Url whatsapp, Purchase advice publinet": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20quiero%20hacer%20una%20campa%C3%B1a%20y%20me%20gustar%C3%ADa%20tener%20asesoramiento.",
    "Url whatsapp, Purchase advice latinad": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20quiero%20hacer%20una%20campa%C3%B1a%20y%20me%20gustar%C3%ADa%20tener%20asesoramiento.",

    "Mailto Work with us": "mailto:info@latinad.com?subject=Quiero trabajar con ustedes&amp;body=Hola, me gustaría trabajar con ustedes. Les dejo información sobre mi:",

    "Our social networks": "Nuestras redes",
    "Find out about the latest news of the platform on our social networks.": "Enterate de las últimas novedades de la plataforma en nuestras redes sociales.",

    // SearchBoxComponent
    "Campaign location": "Zona de campaña",
    "Add Country or City?": "¿A dónde la apuntas?",
    "Campaign start": "Inicio campaña",
    "End of campaign": "Fin campaña",
    "Add dates": "Agrega las fechas",
    "Select a customer and brand to continue": "Seleccione un cliente y una marca para continuar",
    "Traditional": "Tradicional",
    "Programmatic": "Programática",
    "Client": "Cliente",
    "Mark": "Marca",
    "": "",

    // HomeComponent
    "Digital signage": "Cartelería Digital",
    "Digital signage (DOOH)": "Cartelería Digital (DOOH)",
    "High-Value Audiences at a Click": "Audiencias de alto valor a un click",
    "Boost your brand": "Potencia tu marca",
    "Create campaigns on public roads in minutes and reach thousands of people": "Crea campañas en vía pública en minutos y llega a miles de personas",
    "Strategically positioned where your ideal audience is.": "Estratégicamente posicionadas donde está tu audiencia ideal.",
    "Get to know us": "Conócenos",
    "Discover the latest in outdoor advertising": "Descubre lo último en publicidad exterior",
    "Communicate your brand to thousands of people intelligently": "Comunica tu marca a miles de personas de forma inteligente",
    "Without intermediaries": "Sin intermediarios",
    "Without calls": "Sin llamadas",
    "100% Online": "100% Online",
    "Do you have screens?": "¿Tienes pantallas?",
    "Join the largest network in America, manage your business and increase sales.": "Súmate a la red más grande de América, gestiona tu negocio y aumenta las ventas.",
    "Register as media company": "Regístrate",
    "I dont have CMS": "No tengo CMS y quiero ver los beneficios",
    "I have CMS": "Tengo CMS, solo quiero aumentar mis ventas",
    "Know the media details": "Conoce los detalles",
    "Audience measurement with artificial intelligence": "Medición de audiencia con inteligencia artificial",
    "Our campaigns are based on traffic and people metrics. Find out how we do it.": "Nuestras campañas están basadas en métricas de tráfico y personas. Entérate como lo hacemos.",
    "Know the audience details": "Conoce los detalles",
    "Partners": "Partners",
    "Blog": "Noticias y Blog",
    "Text Blog 1": "Crea campañas en LatinAd para +150 DSPs",
    "Description Blog 1": "Lanza campañas en DOOH comprando todos los medios disponibles en Latinoamérica y accediendo a más de 150 DSPs.",
    "Text Blog 2": "Planifica en Latinad e implementa en DSPs externos",
    "Description Blog 2": "Te compartimos el tutorial.",
    "Text Blog 3": "Visita nuestro sitio de ayuda",
    "Description Blog 3": "Help te enseñará de Latinad.",
    "Platform integrated to the DOOH and programmatic Ecosystem": "Plataforma integrada al Ecosistema DOOH y programático",

    // Info
    // "Watch our video": "Mirá nuestro video",
    // "You are part of the OOH advertising world on. Learn here.": "Formas parte del mundo publicitario en vía pública, mirá este video",
    // "3 Simple Steps to start your campaign": "3 Simples pasos",
    // "It is everything you need to launch your campaign on public roads": "Es todo lo que necesitas para lanzar tu campaña en vía pública",
    // "Do you have screens?": "¿Tenés pantallas?",
    // "Join the largest Out of Home network in America": "Unite a la red más grande de pantallas de América",

    // Expansion
    "Where we are?": "¿Dónde estamos?",
    "Argentina": "Argentina",
    "Peru": "Perú",
    "Ecuador": "Ecuador",
    "Colombia": "Colombia",
    "Central America": "Centro América",
    "Chile": "Chile",
    "Mexico": "México",
    "US": "Estados Unidos",

    // UserSettingComponent
    "Account": "Cuenta",
    "Agency": "Agencia",
    "Mail": "Correo",
    "Change Mail": "Cambiar Correo",
    "New Mail": "Nuevo Email",
    "Change Password": "Cambiar Contraseña",
    "Current Password": "Contraseña actual",
    "New Password": "Nueva Contraseña",
    "Repeat New Password": "Repetir Nueva Contraseña",
    "Repeat the old password": "Repetir la contaseña anterior.",

    // QuotesComponent
    "Quotes": "Cotizaciones",
    "Type": "Tipo",
    "Sub type": "Subtipo",
    "Not assigned": "No asignado",
    "See quote": "Ver cotización",
    "Delete quote": "Eliminar cotización",
    "Unarchived quote": "Desarchivar cotización",
    "Archived quote": "Archivar cotización",
    "Quote removed successfully": "Cotización eliminada con éxito",
    "Quote archived successfully": "Cotización archivada con éxito",
    "Quote unarchived successfully": "Cotización desarchivada con éxito",
    "An error occurred while trying to delete quote": "Ocurrió un error al intentar eliminar cotización",
    "An error occurred while trying to update quote": "Ocurrió un error al intentar editar la cotización",
    "Quote updated successfully": "Cotización editada con éxito",
    "Budget": "Presupuesto",
    "Minumum budget": "El presupuesto debe ser mayor a ",
    "Created": "Creado",
    "Start": "Inicio",
    "End": "Fin",
    "Qty. Screens": "Cant. Pantallas",
    "Action": "Acción",

    // CampaignsComponent
    "Campaign successfully removed": "Campaña eliminada con éxito",
    "You cannot delete campaigns with payments created": "No puede eliminar campañas con pagos creados",
    "No campaigns": "No hay campañas",
    "An error occurred while deleting campaign": "Ocurrió un error al eliminar campaña",
    "There is no client": "No existe cliente",
    "There is no brand": "No existe marca",
    "Getting campaigns": "Obteniendo campañas",
    "You havent created your first campaign yet.": "Todavía no creaste tu primer campaña.",
    "Still to be paid": "Pendientes de pago",
    "Pending approval": "Pendientes de aprobación",
    "Active": "Activas",
    "Next": "Próximas",
    "Previous": "Pasadas",
    "Archived": "Archivadas",
    "Atention!": "Atención!",
    "You are about to delete a campaign. Are you agree?": "Está a punto de eliminar una campaña. ¿Está de acuerdo?",
    "You are about archived a campaign, then you can unarchived in seccion archived": "Está a punto de archivar una campaña, luego puede desarchivarla en la sección archivadas",
    "You are about unarchive a campaign": "Está a punto de desarchivar una campaña",
    "OK": "Aceptar",
    "Cancel": "Cancelar",
    "To modify this campaign, use the new version of the DSP with which it was created" : "Para modificar esta campaña, utilice la nueva versión del DSP con la que fue creada",
    "New version": "Nueva versión",
    "Version": "Versión",
    "Are you sure you want to delete this relationship?": "Estás seguro de que deseas eliminar esta relación?",
    "Are you sure you want to delete this relationship?. The content will be removed from the campaign because it will not belong to any relationship.": "Se desvinculará el anuncio del grupo de pantallas de esta resolución y se dejará de usar en la campaña. Puede agregarlo nuevamente después.",
    "Manage ads and screens": "Administrar Anuncios y Pantallas",
    "Ad library": "Librería de anuncios",
    "Campaign Ads": "Anuncios para la campaña",

    // CampaignsCard
    "Ads rejected": "Aviso(s) rechazado(s)",
    "See detail": "Ver detalle",
    "Report": "Reporte",
    "View report": "Ver reporte",
    "Campaign created by": "Campaña creada por",
    "the day": "El día",
    "at": "a las",
    "for a total amount of": "Por un monto total de",
    "Delete campaign": "Eliminar campaña",
    "Archive campaign": "Archivar campaña",
    "Unarchive campaign": "Desarchivar campaña",
    "Campaign created as Programmatic": "Campaña creada como Programática",
    "Campaign created as Traditional": "Campaña creada como Tradicional",
    "Click for more information": "Click para mas información",

     // CampaignsCardModal
     "See campaign":"Ver campaña",

    // Modal Replicate Campaign
    "Replicate campaign": "Replicar campaña",
    "Replicated": "Replicada",
    "The name is required": "El nombre es obligatorio",
    "A campaign with a pending payment status will be created, keeping the contents and screens that are still available for sale.": "Se creará una campaña con estado pendiente de pago, manteniendo los contenidos y pantallas que todavía estén a la venta",
    "The original campaign has rules based on dates, these will be removed. Remember to configure them again.": "La campaña original tiene reglas basadas en fechas, estas serán eliminadas. Recuerda configurarlas nuevamente.",

    // Modal Select Registration Type
    "Welcome to LatinAd": "Bienvenido a LatinAd",
    "Are you an agency?": "¿Sos agencia?",
    "Are you an advertiser?": "¿Sos anunciante?",
    "Company name": "Nombre de la empresa",
    "Country": "País",
    "State (optional)": "Estado (opcional)",
    "User Email": "Correo electrónico",
    "First and last name": "Nombre y apellido",
    "Phone number": "Teléfono",


    // CampaignComponent
    "Screen resolution": "Resolución pantalla",
    "Announcement resolution": "Resolución anuncio",
    "Purchased impressions": "Spots comprados",
    "Promised impressions": "Spots prometidos:",
    "Screen details": "Detalles de la pantalla",
    "There was an error": "Se produjo un error",
    "You do not have permissions to access this campaign": "No tiene permisos para acceder a esta campaña",
    "The requested campaign does not exist": "La campaña solicitada no existe",
    "You must first delete the pending payment before retrying a new payment": "Primero debe eliminar el pago pendiente antes de reintentar un nuevo pago",
    "Payment method": "Método de Pago",
    "Status": "Estado",
    "Detail": "Detalle",
    "Your Ads": "Tus anuncios",
    "Your payments": "Tus pagos",
    "Create new payment": "Crear nuevo pago",
    "Your ads for each screen": "Tus anuncios por cada pantalla",
    "See detailed report": "Ver reporte detallado",
    "The campaign will be paused, which means it will stop running and will not consume budget until it is reactivated.": "La campaña será pausada, lo que significa que dejará de exhibirse y no consumirá presupuesto hasta su reactivación.",
    "The campaign will be activated, which means it will start running and begin consuming budget again until it is paused." : "La campaña será activada, lo que significa que comenzará a exhibirse y volverá a consumir presupuesto hasta que sea pausada.",
    "Activate campaign": "Activar campaña",
    "Pause campaign": "Pausar campaña",
    "Edit campaign": "Editar campaña",
    "View campaign in Campaign Manager": "Ver campaña en el Campaign Manager",
    "The logged-in user is not the owner of this campaign": "El usuario logueado no es dueño de esta campaña, cambie de empresa para acceder a la campaña",
    "Credit/debit card": "Tarjeta de crédito/débito",
    "MercadoPago": "MercadoPago",
    "Cash/Transfer": "Efectivo/Transferencia",
    "Approved": "Aprobado",
    "Paused": "Pausado",
    "Ready": "Activa",
    "Pending": "Pendiente",
    "Finalized": "Finalizado",
    "Processing": "Procesando",
    "Cancelled": "Cancelado",
    "Cancelled by user": "Cancelado por usuario",
    "Refunded": "Reintegrado",
    "In dispute": "En disputa",
    "Voided by claim": "Anulado por reclamo",
    "Spots pending approval of the owner of each screen": "Spots pendientes de aprobación del dueño de cada pantalla",
    "Approved by the owners of each screen": "Aprobadas por los dueños de cada pantalla",
    "Rejected": "Rechazadas",
    "To modify": "A modificar",
    "Campaign pending approval status, check with your manager.": "Campaña en estado pendiente de aprobación, consulte con su director.",
    "Campaign in pending payment status.": "Campaña en estado pendiente de pago.",
    "Consider the following": "Tenga en cuenta lo siguiente",
    "When entering the edit mode of a campaign ": "Al entrar en modo edición de una campaña ",
    "some prices may be changed ": "pueden verse modificados algunos precios ",
    "(only in cases where screen owners have updated those prices), since this campaign does not have payments generated nor has it been launched, therefore that prices have not been frozen.": "(solo en casos donde dueños de pantallas han actualizado esos precios), ya que esta campaña no tiene pagos generados ni ha sido ejecutada, por lo que los precios no han sido congelados.",
    "Entering campaign edit mode. This campaign has approved payment, so only elements that do not alter its price can be edited.":"Entrando al modo de edición de campaña. Esta campaña se encuentra con su pago aprobado, por lo que sólo se podrán editar aquellos elementos que no alteren el precio de la misma.",
    "Go to edit": "Ir a editar",
    "Cancel": "Cancelar",
    "Proposal": "Propuesta",
    "Generate proposal": "Generar propuesta",
    "Campaign details": "Detalles de la campaña",
    "Categories": "Categorías",

    // CampaignTopbar
    "Campaign status": "Estado de campaña",
    "Exhibition date": "Fecha de exhibición",
    "Duration": "Duración",
    "Real Impressions": "Spots reales",
    "Show map": "Mostrar mapa",
    "Starts": "Comienzo",
    "Today": "Hoy",
    "Finished": "Finalizó",
    "Campaign country": "País",

    // PaymentStatusComponent
    "Cash": "Efectivo",
    "Check": "Cheque",
    "Transfer": "Transferencia",
    "Bank data": "Datos bancarios",
    "Current account": "Cuenta Corriente",
    "Direct credit": "Crédito Directo",
    "Payment made through Mercado Pago with Card ending in": "Pago relaizado mediante Mercado Pago con Tarjeta terminada en",
    "Payment made by Mercado Pago": "Pago realizado por Mercado Pago",
    "Card": "Tarjeta",
    "finished in": "terminada en",
    "Unknown payment method": "Medio de pago desconocido",
    "Final consumer": "Consumidor Final",
    "Invoice": "Factura",
    "Your payment has already been approved.": "Tu pago ya fue aprobado.",
    "The payment is pending accreditation.": "El pago está pendiente de acreditación.",
    "The payment is pending payment.": "El pago está pendiente a pagar.",
    "Your payment has been credited successfully.": "Tu pago ha sido acreditado correctamente.",
    "The payment was canceled, you can generate another payment and choose the method you want.": "El pago fue cancelado, puedes generar otro pago y elegir el método que quieras.",
    "We are processing your payment. In less than two business days we will notify you of the result.": "Estamos procesando su pago. En menos de dos días hábiles le notificaremos el resultado.",
    "Check the card number.": "Revisa el número de tarjeta.",
    "Please review the data and try again.": "Revisa los datos e intenta nuevamente.",
    "Check the security code.": "Revisa el código de seguridad.",
    "We were unable to process your payment. Please try another payment method.": "No pudimos procesar tu pago. Por favor, intente con otro medio de pago.",
    "Please call your card to authorize the payment and try again.": "Por favor, llama a tu tarjeta para autorizar el pago e intenta nuevamente.",
    "Your card is not active. Please call the phone number on the back of your card.": "Tu tarjeta no se encuentra activa. Por favor, llama al teléfono que está en el dorso de tu tarjeta.",
    "You already made a payment for that value. If you need to pay again, use another card or another means of payment.": "Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.",
    "Your payment was declined. Choose another of the means of payment, we recommend using cash.": "Tu pago fue rechazado. Elige otro de los medios de pago, te recomendamos con medios en efectivo.",
    "Your card does not have sufficient funds to carry out the operation.": "Tu tarjeta no tiene fondos suficientes para realizar la operación.",
    "Your card cannot process installment payments.": "Tu tarjeta no puede procesar pagos en cuotas.",
    "You have reached the limit of allowed attempts. Choose another card or other means of payment.": "Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.",
    "Start date": "Fecha de inicio",
    "Finish date": "Fecha de finalización",
    "Number of screens": "Cantidad de pantallas",
    "Total campaign price": "Precio total de campaña",
    "Successful payment": "Pago realizado con éxito",
    "Pending payment": "Pago pendiente",
    "Payment canceled": "Pago cancelado",
    "Payment declined": "Pago rechazado",
    "Campaign": "Campaña",
    "Payment": "Pago",
    "Payment method": "Método de pago",
    "Total without tax": "Total sin impuestos",
    "Total with tax": "Total con impuestos",
    "tax included": "imp incluído",
    "more taxation": "más impuestos",
    "+tax": "+imp",
    "Billing information": "Datos de facturación",
    "If you want to pay with another means of payment, you can return to the list of payments in your campaign, delete the pending payment, and make a new payment.": "Si quiere pagar con otro medio de pago, puede volver a la lista de pagos en su campaña, eliminar el pago pendiente, y realizar un nuevo pago.",
    "Go to my campaign": "Ir a mi campaña",
    "Summary": "Resumen",
    "See spot": "Ver spot",
    "Discount": "Descuento",
    "Your ads have been sent to the owners of each screen for their review and approval. This process can take up to 48 hours. We will send you a notification by mail when this process is complete.": "Tus anuncios han sido enviados a los dueños de cada pantalla para su revisión y aprobación. Este proceso puede tomar un tiempo de hasta 48 horas. Te enviaremos una notificación por correo cuando este proceso haya finalizado.",
    "pending": "Pendiente",
    "approved": "Aprobada",
    "cancelled": "Cancelado",
    "rejected": "Rechazado",
    "View purchase orders":"Ver orden de compra",
    "Upload ODC": "Cargar ODC",
    "Date expiry": "Fecha de vencimiento",
    "Document type": "Tipo de documento",
    "Document number": "Número de documento",
    "Name as it appears on the card": "Nombre como aparece en la tarjeta",
    "Campaign name": "Campaign name",

    // AdScreenCard
    "This would modify the value of the campaign, create a new campaign or contact support": "Esto modificaría el valor de la campaña, cree una nueva campaña o puede contactarse con soporte",
    "The file cannot be larger than 50MB!": "El archivo no puede ser mayor a 50MB!",
    "The resolution can not be larger than 10000px": "La resolución no puede ser mayor a 10000 píxeles",
    "successfully uploaded": "subido exitosamente",
    "You cannot upload files longer than those allowed by the programmatic": "No puede subir archivos más largos que los que permite la programática",
    "You cannot upload files longer than what is allowed on the screen": "No puede subir archivos más largos que los que se permiten en la pantalla",
    "Upload error": "Error al subir",
    "Spot Approved": "Aprobado",
    "Spot Rejected": "Rechazado",
    "Real time impressions": "Spots tiempo real",
    "The owner of this screen has not yet approved": "El dueño de esta pantalla aún no aprueba",
    "View screen details": "Ver detalles de la pantalla",
    "Screen Format": "Formato de pantalla",
    "Ad format": "Formato de anuncio",
    "Price": "Precio",
    "Total subsidize": "Total bonificadas",
    "subsidized": "bonificadas",
    "Subsidized": "Bonificada",
    "Pre approved": "Pre aprobado",
    "Coming soon": "Próximamente",
    "Subsidize": "Bonificar",
    "Suspended": "Suspendida",
    "It will be in suspended state if the value is 0.": "Quedara en estado suspendido si el valor es 0.",
    "Campaign subsidized": "Campaña bonificada",
    "Subsidize impression": "Spots bonificados",
    "These impressions are reflected as subsidize in the campaign": "Estos spots se reflejan como bonificadas en la campaña",
    "Preview": "Vista previa",
    "Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration.": "Cambiar este anuncio por otro, requiere aprobación. Recuerde subir anuncios del mismo cliente, marca, campaña y duración similar.",
    "Change this ad for another, requires approval. Remember to upload ads of the same client, brand, campaign and similar duration. No immediately": "Cambiar este anuncio por otro, requiere aprobación. Recuerde subir anuncios del mismo cliente, marca, campaña y duración similar. Esta acción puede tomar hasta dos horas en ser efectiva luego de la aprobación.",
    "Change ad": "Cambiar anuncio",
    "This action will change the ad (new piece that will go up). It does require a new approval in the event that the previous piece has already been approved. Remember to upload a similar piece, from the same campaign, client and brand.": "Esta acción cambiará el anuncio (pieza nueva que subirá). Requiere de una nueva aprobación en el caso de que ya haya sido aprobada la anterior pieza. Recuerde subir una pieza similar, de la misma campaña, cliente y marca.",
    "Camera to traffic": "Cámara al tráfico",
    "View live traffic": "Ver tráfico en vivo",
    "Traffic camera for audience control and measurement": "Cámara al tráfico para control y medición de audiencia",
    "You have not configured a camera pointing at traffic": "No ha configurado cámara apuntando al tráfico",
    "No data": "No hay datos",
    "Traffic today": "Tráfico de hoy",
    "Real-time vehicular traffic count": "Conteo de tráfico vehicular en tiempo real",
    "Traffic this week vs previous week": "Tráfico en esta semana vs semana anterior",
    "Weekly count by type": "Conteo semanal por tipo",
    "Movement per hour in the week": "Movimiento por hora en la semana",
    "Camera for certification": "Cámara para certificación",
    "View live screen": "Ver pantalla en vivo",
    "Camera looking at screen for certification": "Cámara mirando la pantalla para certificación",
    "You have not configured camera pointing at the screen": "No ha configurado cámara apuntando a la pantalla",
    "See evidence": "Ver evidencias",
    "Evidence": "Evidencias",
    "M": "L",
    "T": "M",
    "W": "M",
    "TH": "J",
    "F": "V",
    "SA": "S",
    "SU": "D",
    "Every day": "Todos los días",
    "All day": "Todo el día",
    "Rule": "Regla",

    // CartComponent
    "Quote created successfully": "Cotización creada con éxito",
    "An error occurred while trying to create a quote": "Ocurrió un error al intentar crear cotización",
    "See cart": "Ver Carrito",
    "to cart": "al carrito",
    "Estimated campaign budget": "Presupuesto estimado de campaña",
    "The minimum purchase price is not reached": "No llega al precio mínimo de compra",
    "In the next step you will be able to configure the impressions per screen and you will see how the budget varies according to the impact you want.": "En el siguiente paso podrá configurar los spots por pantalla y verá como varía el presupuesto según el impacto que desee.",
    "You must select a customer and brand before starting a campaign launch": "Debe seleccionar un cliente y una marca antes de comenzar el lanzamiento de una campaña",
    "You must select a customer and brand before creating a quote": "Debe seleccionar un cliente y una marca antes de crear una cotización",
    "CREATE PROPOSAL": "GENERAR COTIZACIÓN",
    "EXPORT EXCEL": "Exportar Excel",
    "EXPORT MAP": "Exportar Mapa",
    "There are no more screens in the cart": "No hay más pantallas en el carrito",
    "Empty cart": "Vaciar carrito",
    "Create Quote": "Crear Cotización",
    "Ads cannot be edited in approved campaigns":"No se pueden modificar anuncios en campañas aprobadas",
    "Editing the campaign": "Editando campaña",
    "Exit edit": "Salir de editar",
    "Edit": "Editar",
    "If you exit out of edit mode, the cart and contents empty": "Saliendo del modo editar se vacia el carrito y los contenidos cargados.",
    "Slot duration": "Duración del anuncio",
    "The minimum purchase price is not reached": "No llega al precio mínimo de compra",
    "The minimum purchase price is": "El precio mínimo de compra es",
    "Currency exchange rate": "Tasa de conversión",
    "See currency exchange rate": "Ver tasa de conversión",
    "Conversion generated at 11AM (Argentina time) today": "Conversión generada a las 11AM (hora Argentina) de hoy",

    // CheckoutDetails
    "Total cost": "Total",
    "Subtotal cost": "Subtotal",
    "Suggested price in local currency": "Precio sugerido en moneda local",
    "It is the price that you should use when implementing the campaign in the chosen DSP, only if you want to do it in local currency.": "Es el precio que debería utilizar al momento de implementar la campaña en el DSP elegido, solo en el caso que desee hacerlo en moneda local.",
    "Tax details": "Impuestos incluídos",
    "Finish": "Finalizar",
    "Finish process for subsequent approval": "Terminar proceso para su posterior aprobación",
    "Make payment": "Realizar pago",
    "View campaign": "Ver campaña",
    "Go Mercado Pago": "Ir a Mercado Pago",
    "Apply discount coupon": "Aplicar cupón de descuento",
    "Coupon code": "Código de Cupón",
    "Remove discount": "Eliminar descuento",

    // FiltersComponent
    "Client and brand": "Cliente / Marca",
    "Change campaign dates": "Cambiar fechas de campaña",
    "Price per day per screen": "Precio por día por pantalla",
    "Impressions per day per screen": "Spots por día por pantalla",
    "Impressions": "Spots",
    "All": "Todas",
    "Mobile": "Movil",
    "Outdoor": "Exterior",
    "Indoor": "Interior",
    "Point of sale": "Punto de venta",
    "Size": "Tamaño",
    "Small": "Chica",
    "Medium": "Mediana",
    "Big": "Grande",
    "Giant": "Gigante",
    "Other Filters": "Otros Filtros",
    "Circuit": "Mostrar solo circuitos",
    "Show and edit filters": "Ver y editar filtros",
    "circuit": "Circuito",

    // ScreenCardComponent
    "Real-time audience": "Audiencia en tiempo real",
    "Audience detail": "Detalle de audiencia",
    "Non-automated screen. Click to see detail": "Pantalla no automatizada. Click para ver detalles.",
    "Non-automated screen": "Pantalla no automatizada.",
    "This screen does not have the real-time print notice. Therefore, print reports may take up to 48 hours to be reflected.": "Esta pantalla no cuenta con el aviso de spots en tiempo real. Por lo que los reportes de spots pueden demorar en verse reflejados, y pueden verse desfasadas en el tiempo.",
    "Screen belonging to the circuit": "Pantalla perteneciente al circuito",
    "of count_secondary_displays screens": "de count_secondary_displays pantallas.",
    "It is not possible to purchase these screens individually." : "No es posible comprar estas pantallas individualmente.",
    "Circuit": "Circuito",
    "spots/day": "spots / día",
    "Audience Average": "Audiencia promedio",
    "Average audience of your screen": "Audiencia promedio de tu(s) pantalla(s)",
    "Audience by day of the week": "Audiencia promedio por días de la semana",
    "Audience by time of day": "Audiencia promedio por horas del día",
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "Average audience of your screen by day of the week": "Audiencia promedio de tu(s) pantalla(s) por día de la semana",
    "Average audience per hour in the day": "Audiencia promedio por hora en el día",
    "Select days of the week": "Seleccione días de la semana",
    "Filter audience by day of the week": "Filtrar audiencia por día de la semana",
    "Filter": "Filtrar",
    "Average audience by date": "Audiencia promedio por fecha",
    "Select hours of the day": "Seleccionar horas del día",
    "Filter audience by day of the week or hour": "Filtrar audiencia por día de la semana u hora",
    "Total number of people impacted": "Total de personas impactadas",
    "Day of greatest impact": "Día de mayor impacto",
    "Biggest Impact/Day": "Mayor Impacto / Día",
    "Hour with the greatest impact": "Horario con mayor impacto",
    "Biggest Impact/Hour": "Mayor Impacto / Hora",
    "Sum of Impacts per day (Dates)": "Suma de Impactos por dia (Fechas)",
    "Average Impacts per Hour (Dates)": "Promedio de Impactos por Hora (Fechas)",
    "Comparison by Screen": "Comparación por Pantalla",
    "Sum of Impacts per day": "Suma de impactos por día",

    // RequestSpecialCpmComponent - RequestSpecialPriceComponent
    "Agreed prices": "Precios acordados",
    "Send request": "Enviar solicitud",
    "You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and LatinAd will notify you when the set price is reached to continue with your purchase.": "Podrá solicitar al medio el precio acordado ingresando la cantidad acordada a continuación, al enviarlo, el medio recibirá un mail con la solicitud y LatinAd le avisará cuando sea aceptada para continuar con su compra.",
    "You can request the agreed price to the media company by entering the agreed amount below. When you send it, the media company will receive an email with the request and the system will notify you when the set price is reached to continue with your purchase.": "Podrá solicitar al medio el precio acordado ingresando la cantidad acordada a continuación, al enviarlo, el medio recibirá un mail con la solicitud y el sistema le avisará cuando sea aceptada para continuar con su compra.",
    "The original price is" : "El precio original es de",
    "per thousand spots.": "por mil spots.",
    "per day.": "por día.",
    "Agreed price": "Precio acordado",
    "Final prices may contain platform commissions and may vary depending on the selected client": "Los precios finales pueden contener comisiones de plataformas y pueden variar dependiendo el cliente seleccionado",
    "Request agreed price": "Solicitar precio acordado",
    "The agreed price must be greater than 0": "El precio acordado debe ser mayor a 0",
    "The agreed price must be less than the original price": "El precio acordado debe ser menor al precio original",
    "There is already a cpm price arrangement request for this display": "Ya existe una solicitud de acuerdo de precio para esta pantalla",
    "There is already a cpm price arrangement for this display": "Ya existe un acuerdo de precio con esta pantalla",
    "There is already a price arrangement request for this display": "Ya existe una solicitud de acuerdo de precio para esta pantalla",
    "There is already a price arrangement for this display": "Ya existe un acuerdo de precio con esta pantalla",
    "Request sent successfully": "Soliciud enviada con éxito",
    "To keep in mind": "A tener en cuenta",
    "The agreed-upon price does not include local or international taxes.":"El precio acordado no incluye impuestos locales ni internacionales.",
    "Final prices may include platform fees and may vary depending on the selected client.": "Los precios finales pueden contener comisiones de plataformas y pueden variar dependiendo el cliente seleccionado.",
    "In the event that the screen price is in a different currency than the one being used for the purchase, final prices may vary due to conversion.": "En el caso en que el precio de la pantalla esté en una moneda diferente a la que se intenta comprar, en el precio final pueden presentarse variaciones por conversión.",
    "You already have an agreed price": "Ya tiene un precio acordado",

    // CardComponent
    "screens that you added to the cart have cameras measuring audience. You can go to the detail of each one to analyze the data and optimize your campaign": "pantallas que agregó al carrito tienen medición de audiencia. Puede ir al detalle de cada una para analizar los datos y optimizar su campaña",
    "Screens measuring audience": "Pantallas midiendo audiencia",
    "Estimated impressions based on campaign settings. You can add campaign days (in traditional) or increase the budget (in programmatic).": "Número de personas que verán su anuncio o grupo de anuncios según la configuración de la campaña.",
    "Total screens added in the campaign": "Pantallas totales agregadas en la campaña",
    "Estimated audience": "Audiencia estimada",
    "See audience details": "Ver detalle de estimado de audiencia",
    "See audience button": "Ver",
    "Audience impacts": "Alcance",
    "impacts": "Impactos",
    "Upload material": "Subir anuncios",
    "Value based on screen occupancy statistics, so it can vary daily.": "Valor basado en las estadística de ocupación de la pantalla, por lo que puede variar diariamente.",
    "(CPM) cost per thousand impression": "(CPM) costo por mil impactos",

    // CheckoutStepsComponent
    "Campaign budget": "Ppto. de campaña",
    "You can configure the impressions per screen and see how the updated budget varies according to the impact you want.": "Puede configurar los spots por pantalla y ver como varía el presupuesto actualizado según el impacto que desee.",

    // StepsComponent
    "Select screens": "Seleccioná pantallas",
    "Upload your spots": "Subí tus spots",
    "Launch your campaign": "Lanzá tu campaña",

    //Contents of campaign
    "Required resolutions": "Resoluciones requeridas",
    "Required resolution": "Resolución requerida",
    "Tolerance": "Tolerancia",
    "Clear progress": "Limpiar progreso",
    "Clear all contents added to the campaign, clearing its progress": "Limpiar todo el contenido agregado a la campaña, limpiando su progreso",
    "Info tolerance": "Si se modifica el valor de tolerancia, las relaciones creadas entre el contenido y las pantallas serán más amplias, ya que se basan en ocupación del contenido en la pantalla.",
    "for the screen": "Para la pantalla",
    "for these versions": "para estas versiones",
    "for screens grouped as": "Para las pantallas agrupadas como",
    "To replace this content": "Para remplazar este contenido",

    // VideoUploadComponent
    "the file is not ready yet": "el archivo todavía no esta listo",
    "upload a file for the campaign": "subir un archivo para la campaña",
    "Campaign successfully created": "Campaña creada con éxito",
    "You can see it in the My campaigns section.": "Puede verla en la sección Mis campañas.",
    "You have not selected screens, you can go back to the map and do it from there.": "No ha seleccionado pantallas, puede volver al mapa y hacerlo desde ahí.",
    "Your campaign": "Tu campaña",
    "Contents library": "Librería de anuncios",
    "Content added in campaign list": "Anuncio agregado en la lista de campaña",
    "You must select a content": "Debes seleccionar un contenido",
    "Screens": "Pantallas",
    "Aspect ratio": "Relación de aspecto",
    "General dimensions": "Dimensiones generales",
    "Resolution": "Resolución",
    "Type of screen": "Tipo de pantalla",
    "Ubication": "Ubicación",
    "Group by":"Agrupar por",
    "Contents for the campaign": "Anuncios para la campaña",
    "Give your campaign a name so you can identify it": "Nombra tu campaña para que puedas identificarla",
    "Add more information if you need it" : "Agrega mas información",
    "You reached the character limit!" : "¡Alcanzaste el límite de caracteres!",
    "Campaign name": "Nombre de campaña",
    "Campaign extra information": "Información adicional de la campaña",
    "Is it a test campaign?": "¿Es campaña de prueba?",
    "Pause campaign": "Pausar campaña",
    "Pause content": "Pausar anuncio",
    "Pause display": "Pausar pantalla",
    "You have already uploaded your ad! If you want to change it for all screens, click below": "¡Ya subiste tu anuncio!, si quieres cambiarlo para todas las pantallas haz click a continuación.",
    "Change spot material": "Cambiar anuncio",
    "general rule": "regla general",
    "Delete ad": "Limpiar anuncio",
    "General Rule (will apply to all screens)": "Regla General (se aplicará a todas las pantallas)",
    "Delete material": "Limpiar anuncio",
    "Clear campaign progress in progress": "Limpiar progreso de campaña en proceso",
    "Yes, clean": "Si limpiar",
    "All the progress you have made so far in this campaign will be cleaned up, such as the campaign name, the ads for each screen, and the settings.": "Se limpiará todo el progreso que ha hecho hasta el momento en esta campaña, como el nombre de la campaña, los anuncios para cada pantalla y las configuraciones.",
    "Upload your ad": "Carga tu anuncio",
    "Upload your ad (an image or a video), to preview how it will look on each of the screens you chose.": "Sube tu anuncio (una imagen o un video), para previsualizar como quedará en cada una de las pantallas que elegiste.",
    "Drag the ad here or": "Arrastra el anuncio acá o",
    "click to load it.": "haz click para cargarlo.",
    "Dont have the ad?": "¿No tienes el anuncio?",
    "Contact us and we will advise you.": "Contactanos y te asesoramos.",
    "Url whatsapp, Contact us": "https://api.whatsapp.com/send?phone=5492615019508&text=Hola,%20me%20gustaría%20hacer%20una%20publicidad%20pero%20todavía%20no%20tengo%20la%20pieza%20gráfica.%20",
    "The time indicated by each screen will be displayed.": "Se exhibirá el tiempo que indica cada pantalla.",
    "You can change the ad for each of the selected screens. Keep in mind that if it is an image, the ad will display the space time indicated by each screen. For videos, it will be the time of the video.": "Puedes cambiar el anuncio para cada una de las pantallas seleccionadas. Ten en cuenta que si es una imagen, el anuncio se exhibirá el tiempo del espacio que indica cada pantalla. Para los videos, será el tiempo del video.",
    "Selected screens": "Pantallas seleccionadas",
    "Updated budget": "Presupuesto actualizado",
    "Redistribute": "Redistribuir",
    "Upload your main piece to see how it will look on each screen": "Suba su pieza principal para ver como se verá en cada pantalla",
    "Your video of": "Tu video de",
    "secs exceeds the playing time accepted by some selected screens. You can change the ad for a shorter one, or you can leave it and pay the proportional value for the time exceeded.": "segs excede el tiempo de reproducción aceptado por algunas pantallas seleccioandas. Puedes cambiar el anuncio por uno de menor tiempo, o puedes dejarlo y pagar el valor proporcional por el tiempo excedido.",
    "secs exceeds the standard time for programmatic campaigns of 10 seconds. You can change the ad for a shorter time.": "segs excede el tiempo estándar de las campañas programáticas de 10 segundos. Puedes cambiar el anuncio por uno de menor tiempo.",
    "Your ad is": "Tu anuncio es",
    "an image": "una imagen",
    "a video": "un video",
    "Printed so far": "Exhibido hasta ahora",
    "It has a duration of": "Tiene una duración de",
    "secs, and this time will be displayed": "segs, y se exhibirá este tiempo",
    "Campaign successfully edited": "Campaña editada con éxito",
    "You can review it from this section before generating a payment.": "Puede revisarla desde esta sección antes de generar un pago.",
    "Campaign": "Campaña",
    "You can exit edit mode to create a new campaign.": "Puede salir del modo editar, para crear una campaña nueva.",
    "You are in campaign edit mode": "Está en modo editar campaña",
    "Exit": "Salir",
    "Go back to the original budget and recalculate impressions (changes you have made in impressions for each Spot will be erased)": "Volver al presupuesto original y recalcular spots (se borrarán cambios que haya hecho en spots por cada Anuncio)",
    "Go back to the original budget (n_amountBudget) and recalculate impressions (changes you have made in impressions for each Spot will be erased)": "Volver al presupuesto original (n_amountBudget) y recalcular spots (se borrarán cambios que haya hecho en spots por cada anuncio)",
    "Redistribute the new budget and recalculate spots (changes made to spots for each ad will be deleted)": "Redistribuir el nuevo presupuesto y recalcular spots (se borrarán cambios que haya hecho en spots por cada anuncio)",
    "The initial amount was modified; you can redistribute the budget": "El monto inicial fue modificado, puedes redistribuir el presupuesto.",
    "Increase prints to fill all remaining": "Aumentar los spots para ocupar todas las restantes",
    "Design and change spot": "Diseñar y cambiar anuncio",
    "Design spot": "Diseñar anuncio",
    "What resolution will your main Spot have?": "De que resolución será tu Spot principal?",
    "Width (px)": "Ancho (px)",
    "Height (px)": "Alto (px)",
    "You will need to create an account and log in the first time you want to create a design.": "Debe crear una cuenta e inciar sesión la primera vez que quiera crear un diseño.",
    "Identify your ad...": "Identificá tu anuncio...",
    "None": "Ninguna",
    "Contents": "Contenidos",
    "Configuration": "Configuración",
    "Make payment": "Realizar pago",
    "View contents from the folder": "Carpeta de anuncios",
    "Upload": "Cargar",
    "Select a person": "Seleccinar una carpeta",
    "None": "Ninguna",
    "There are ads that do not adapt correctly to the resolutions. You can continue or select ads from closer resolutions." : "Hay anuncios que no se adecuan correctamente a las resoluciones. Puede continuar o seleccionar anuncios de resoluciones más cercanas.",

    //ContentsLibraryComponent
    "Library of contents": "Librería de anuncios",
    "Choose the version before choosing content": "Seleccionar una versión antes de elegir contenido",
    "Remove content from the library": "Eliminar contenido de la librería",
    "Add content to the campaign": "Agregar anuncio a la campaña",
    "This ad does not fit the screen resolution according to the tolerance set in the campaign": "Este anuncio no se ajusta a la resolución de la pantalla según la tolerancia establecida en la campaña",
    "Remove contents from the campaign": "Quitar anuncios de la campaña",
    "Apply ad to available resolutions": "Aplicar anuncio a resoluciones disponibles",
    "Are you sure you want to remove this content from the campaign": "¿Estás seguro de que desea quitar este contenido de la campaña?",
    "Remove relation between content and displays": "Quitar relación entre anuncio y pantallas",
    "It's the last relation on a screen, to delete it, its contents should be reorganized": "Es la ultima relación de una pantalla, para eliminarla deberia reorganizar sus contenidos",
    "version": "Versión",
    "Apply rule by versions": "Aplicar regla por versiones",
    "Remplace version": "Reemplazar versión",
    "Remplace": "Reemplazar",
    "Create another version": "Crear otra versión",
    "A version with that resolution already exists, you want to replace the content": "Ya existe una versión con esa resolución, deseas reemplazar el contenido",
    "A version with that resolution already exists on another screen, a new version will be created to avoid replacing the one currently in use.": "Ya existe una versión con esa resolución en otra pantalla. Se creará una nueva versión para evitar reemplazar la que está en uso.",
    "Select a folde": "Seleccionar una carpeta",
    "This folder is not modified": "Esta carpeta no se modifica",
    "Content information": "Información del contenido",
    "Displays added in this relation": "Pantallas agregadas en esta relación",
    "Hours": "Horas",

    // ModalContent
    "Content data": "Datos de anuncio",
    "Are you sure you want to delete this content?": "¿Está seguro de que desea dejar de usar este anuncio en la campaña? Puedes agregarlo nuevamente después.",
    "Are you sure you want to delete all progress so far? It will remove the loaded ads and created relationships.": "¿Estás seguro de que quieres eliminar todo el progreso hasta ahora? Eliminara los anuncios cargados y las relaciones creadas.",
    "Delete": "Eliminar",

    //CardGeneralComponent
    "General": "General",
    "Number of screens": "Cantidad de pantallas",
    "Total price": "Precio total",

    // ScreenPreview
    "Add a Spot for this screen. (In case they meet the same rules, it will print once each)": "Agregar un Spot para esta pantalla. (En caso de que cumplan las mismas reglas, imprimirá una vez cada uno)",
    "In programmatic the ads should not exceed the maximum time of the advertising space on the screen, otherwise they could be cut off in this time": "En programática los anuncios no deben superar el tiempo máximo del espacio publicitario de la pantalla, caso contrario podrían verse cortados en este tiempo",
    "Exceeded in": "Excedido en",
    "secs": "segs",
    "If you do not change the ad for a shorter one, it will add approximately": "Si no cambia el anuncio por uno más corto, se sumará aproximadamente",
    "per day for this screen.": "por día por esta pantalla.",
    "Expand ad": "Ajustar tamaño de pieza a la resolución de la pantalla",
    "Return original resolution": "Volver resolución original",
    "Ad created in canva, you can edit and change it for this screen or relationship.": "Anuncio creado en canva, puede editarlo y cambiarlo para esta pantalla o relación.",
    "Create new ad in canva for this relationship": "Crear anuncio nuevo en canva para esta relación",
    "Change material": "Cambiar pieza",
    "Change ad for this": "Cambiar anuncio para esta",
    "Change ad for this screens": "Cambiar anuncio para estas pantallas",
    "relationship": "relación",
    "Remove spot": "Quitar spot",
    "Screen detail": "Detalle de pantalla",
    "Remove screen": "Quitar pantalla",
    "Add another rule": "Agregar otra regla",
    "Set up rules in this display": "Configurar reglas en esta pantalla",
    "Set up rules in this spot": "Configurar reglas en este spot",
    "Remove rule": "Quitar regla",
    "Remove rules": "Quitar reglas",
    "Clone rules": "Clonar reglas",
    "Standard time of the programmatic space, an extra could be charged in the case of a longer ad.": "Tiempo estándar del espacio programático, podría cobrarse un extra en el caso de ser un anuncio de mayor tiempo.",
    "Space time": "Tpo del espacio",
    "Standard time of the space of this screen, an extra could be charged in the case of being a longer ad.": "Tiempo estándar del espacio de esta pantalla, podría cobrarse un extra en el caso de ser un anuncio de mayor tiempo.",
    "Potential reach in real time of the screen based on the measurement data with cameras, together with the dates and rules selected. Remember to optimize your campaign based on audience data. Also remember that the greater the number of spots, the greater impression you can achieve.": "Alcance potencial en tiempo real de la pantalla en base a los datos de audiencia, junto a las fechas y reglas seleccionadas. Recuerda optimizar tu campaña en base a los datos de audiencia. También recuerda que a mayor cantidad de spots, mayor impacto puede lograr.",
    "Real-time screen impacts, shows the number of people who will see your spot or group of spots based on camera measurement data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impact it will generate.": "Impactos en tiempo real de la pantalla, muestra la cantidad de personas que verán su anuncio o grupo de anuncios en base a los datos de audiencia, junto a las fechas, reglas seleccionadas e inversión realizada. Recuerde que a mayor inversión mayor impacto generará",
    "Audience data provided by the media. They are impression based on third-party measurement data. Remember to optimize your campaign based on audience data. Also remember that the more spots, the more impression you can achieve.": "Datos de audiencia brindada por el medio. Basados en datos de medición de terceros. Recuerde optimizar su campaña en base a los datos de audiencia. Tenga presente que a más spots, mayor impacto puede lograr.",
    "See screen detail to see the days and hours of operation, to optimize your rules": "Ver detalle de pantalla para ver los días y horarios de funcionamiento, para optimizar sus reglas",
    "Subsidize price of this screen": "Bonificar precio de esta pantalla",
    "Subsidize price of campaign": "Bonificar precio de campaña",
    "First load content for subsidize the campaign": "Primero cargar contenido para bonificar la campaña",
    "By enabling this option, the screen is subsidize, affecting the price of the campaign": "Habilitando esta opción la pantalla queda bonificada, afectando el precio de la campaña",
    "By enabling this option, the spot is subsidize, affecting the price of the campaign": "Habilitando esta opción el spot queda bonificado, afectando el precio de la campaña",
    "By enabling this option, the campaign is subsidize, your price will be zero": "Habilitando esta opción la campaña queda bonificada, su precio va a ser cero",
    "Avoid getting your ad disapproved, add %deference_cpm impressions to cover the CPM!": "¡Evite que rechacen tu anuncio, agrega %deference_cpm spots para cubrir el CPM!",
    "Congratulations! You reached the minimum CPM.": "¡Felicitaciones! Llegaste al CPM minimo.",
    "Congratulations! You exceeded the minimum CPM.": "¡Felicitaciones! Pasaste el CPM.",
    "To delete need at least two screens in the campaign": "Para eliminar se necesitan al menos dos pantallas en la campaña",
    "Setting this value in all screen and spot": "Establecer este valor en todas las pantallas y spot",
    "Add contents on the screen": "Agregar anuncios en la pantalla",
    "Add contents to screen group": "Agregar anuncios al grupo de pantallas",
    "Set up rules in group of displays": "Configurar reglas en grupo de pantallas",
    "Set up rules in campaign": "Configurar reglas en campaña",
    "Clone spot": "Clonar spot",

    // PaymentComponent
    "Error applying coupon": "Error al aplicar cupón",
    "The coupon has already been applied": "El cupón ya fue aplicado",
    "Coupon does not exist": "El cupón no existe",
    "A coupon has already been applied in this campaign": "Ya se aplicó un cupón en esta campaña",
    "An error occurred while applying coupon": "Ocurrió un error al aplicar cupón",
    "Coupon applied successfully": "Cupón aplicado con éxito",
    "Failed to delete coupon": "Error al eliminar cupón",
    "Coupon removed successfully": "Cupón eliminado con éxito",
    "The campaign has already been paid.": "La campaña ya ha sido pagada.",
    "Congratulations! Your payment has been credited.": "¡Felicitaciones! Su pago ha sido acreditado.",
    "You must select a country": "Debe seleccionar algún país",
    "The month / year": "El mes / año",
    "Its not valid": "no es válido",
    "Campaign payment": "Pago de campaña",
    "NAME LAST NAME": "NOMBRE APELLIDO",
    "Pay by credit / debit card": "Pagar con tarjeta de crédito / débito",
    "Pay with other means of payment": "Pagar con otros medios de pago",
    "Pay with Mercado Pago": "Pagar con Mercado Pago",
    "Information for direct cash payments with": "Información para pagos en efectivo directo con",
    "Please complete the form with your billing information and your contact telephone number, a n_label collector will contact you to coordinate the collection.": "Por favor completa el formulario con los datos de facturación y tu teléfono de contacto, un cobrador de n_label te contactará para coordinar el cobro.",
    "Telephone contact": "Teléfono de contacto",
    "Bank data": "Datos bancarios",
    "Card number": "Número de Tarjeta",
    "Please make a transfer to the following account, and put the number as a reference": "Por favor realiza una transferencia a la siguiente cuenta, y coloca como referencia el número",
    "Information for direct current account payments with": "Información para pagos en cuenta corriente directo con",
    "Once the payment has been made using this method, your media manager will be able to approve the campaign.": "Una vez realizado el pago con este método, su director de medios podrá aprobar la campaña.",
    "Billing Country": "País de Facturación",
    "Document type": "Tipo de documento",
    "Document number": "Número de Documento",
    "export": "exportación",
    "Fill in the following information": "Complete los siguientes datos para su factura",
    "Address": "Dirección",
    "Street": "Calle",
    "Social name": "Nombre / Razón social",
    "Number": "Número",
    "Province": "Provincia",
    "Location": "Localidad",
    "Billing to client": "Factura a cliente",
    "Billing to agency": "Factura a agencia",
    "Last billing": "Última factura",
    "Additional Information": "Información adicional",
    "Security code": "Código de Seguridad",
    "Billing": "Facturación",
    "Postal code": "Código postal",
    "Generate ODC": "Generar ODC",
    "Creating ODC": "Creando ODC",
    "Created ODC": "ODC creado",
    "Ups, problem for create ODC": "Ups, problema para crear ODC",
    "Creating ODC": "Creando ODC",
    "Change to new version": "Cambiar a nueva versión",

    // ODC modal
    "Purchase orders": "Órdenes de compra",
    "Date creation": "Fecha de creación",
    "Files": "Archivos",
    "There are no purchase orders uploaded": "No hay ordenes de compra cargadas",
    "view": "Ver",
    "ODC": "ODC",

    // PaymentTopbar
    "Go to map": "Ir al mapa",

    // ScreenComponent
    "South": "Sur",
    "North": "Norte",
    "West": "Oeste",
    "East": "Este",
    "Northeast": "Noreste",
    "Northwest": "Noroeste",
    "Southeastern": "Sureste",
    "Southwest": "Suroeste",
    "Class": "Clase",
    "Middle": "Media",
    "High": "Alta",
    "Lower": "Baja",
    "Mon": "Lun",
    "Tue": "Mar",
    "Wed": "Mie",
    "Thu": "Jue",
    "Fri": "Vie",
    "Sat": "Sab",
    "Sun": "Dom",
    "Off": "Apagada",
    "Remove from cart": "Quitar",
    "Add to cart": "Agregar",
    "Go to buy": "Ir a comprar",
    "Description": "Descripción",
    "Information": "Información",
    "Resolution": "Resolución",
    "Ads time": "Tiempo de anuncios",
    "Length of ads time": "Duración de los anuncios",
    "It is an approximate value, because is upload by screen owner.": "Este valor es aproximado, es cargado por el dueño de la pantalla.",
    "There is no audience measurement configured": "No tiene configurado la medición de audiencia",
    "Ad Duration": "Duración de anuncio",
    "Approximate impressions": "Spots aprox",
    "Approx impr": "Spots aprox.",
    "It is an approximate value, because it depends on the days and rules you choose in your campaign. Once the campaign is created, you will get the exact impressions.": "Es un valor aproximado, por que depende de los días y reglas que elija en su campaña. Una vez creada la campaña, obtendrá los spots exactas.",
    "Hours of Operation": "Horario de funcionamiento",
    "Lit all day every day": "Encendida todo el día todos los días",
    "Allowed ad format": "Formato de anuncio permitido",
    "Ad rotated": "Anuncio girado",
    "to the right": "a la derecha",
    "to the left": "a la izquierda",
    "Image": "Imágen",
    "Video": "Video",
    "HTML": "HTML",
    "Quality": "Calidad",
    "Orientation": "Orientación",
    "Floor elevation": "Elevación del suelo",
    "meters": "metros",
    "Viewing distance": "Distancia de visualización",
    "Target": "Target",
    "Restrictions": "Restricciones",
    "Admit any ad or spot that respects everyday rules": "Admite cualquier anuncio o spot que respete las normas cotidianas",
    "Live cameras": "Cámaras en vivo",
    "Traffic": "Tráfico",
    "Audience": "Audiencia",
    "You have not configured a camera to measure the audience": "No ha configurado cámara para medir la audiencia",
    "Show original": "Mostrar original",
    "Translate to": "Traducir a",
    "List of days": "Lista de días",
    "Details": "Detalles",
    "Daily audience": "Audiencia diaria",
    "Weekly audience": "Audiencia semanal",
    "Monthly audience": "Audiencia mensual",
    "Map": "Mapa",
    "Multiple screens": "Múltiples pantallas",
    "Without restrictions": "Sin restricciones",
    "Cost per day": "Costo por día",
    "CPM impacts": "CPM impactos",
    "CPM spots": "CPM salidas",
    "Cost per thousand impacts": "Costo por mil impactos",
    "Cost per thousand spot": "Costo por mil salidas",

    // OnboardingComponent
    "Interest targeting": "Segmentación por interés",
    "Automobiles": "Automóviles",
    "Show all": "Mostrar todas",
    "Business": "Negocios",
    "Services": "Servicios",
    "Entertainment": "Entretenimiento",
    "Education": "Educación",
    "Banking": "Banca",
    "Transport": "Transporte",
    "Government": "Gobierno",
    "Hospitality": "Hotelería",
    "Food": "Comida",
    "Health & Beauty": "Salud y belleza",
    "Temperature": "Temperatura",
    "Humidity": "Humedad",
    "Estimated audience for this location": "Audiencia estimada de esta localización",
    "Average per print": "Promedio por spot",
    "Estimated traffic": "Tráfico estimado",
    "Active screens": "Pantallas activas",
    "Estimated audience": "Audiencia",
    "Screens with cameras measuring traffic and people": "Pantallas con cámaras midiendo tráfico y personas",

    // Map
    "Remember to zoom out the map to see more results": "Recuerda alejar el mapa para ver más resultados",
    "Only screens that are within the visible area of the map will be displayed.": "Solo se mostrarán pantallas que se encuentren dentro del área visible del mapa.",
    "If the screen is at its maximum capacity, at least your ad will be displayed": "Si la pantalla se encuentra con su capacidad máxima, como mínimo tu anuncio se exhibirá",
    "times per hour. Otherwise it will display more": "veces por hora. De lo contrario se exhibirá más",
    "Recommended duration": "Duración spots",
    "An extra may be charged in the event that your ad times out on some screens": "Podría cobrarse un extra en el caso que su anuncio exceda el tiempo en algunas pantallas",
    "Location, street or city": "Lugar, calle o ciudad",
    "Search on move": "Buscar al mover",
    "Programmatic advanced filters": "Filtros avanzados programática",
    "Getting results": "Obteniendo resultados",
    "Multiple faces screen": "Mútliples caras",
    "sides": "caras",
    "Circuit of n_screen screens" : "Circuito de n_screen pantallas",
    "Selection filters": "Filtros de selección",
    "Countries": "Países",
    "Results are filtered by country. You can" : "Los resultados están filtrados por país. Puedes",
    "remove that filter and try again." : "eliminarlo y volver a intentar.",
    "Map filters": "Filtros en mapa",
    "Search by place": "Buscar por lugar",
    "Refinate your search": "Refina tu búsqueda",

    // ScreenTypePreviewComponent
    "screens with": "pantallas con",
    "mixed content": "contenido mixto",
    "Exceeded on some screen": "Excedido en alguna pantalla",
    "of": " de ",
    "The spot will be seen n_days days, with n_impressions impressions per days" : "La pieza se verá n_days días, con n_impressions spots por día",
    "Unable to distribute due to lack of remaining prints" : "Imposible repartir por falta de spots restantes",
    "Remaining to consume": "Restantes a consumir",
    "Impressions details": "Detalle de spots",
    "Consumed": "Consumidas",
    "To consumed": "A consumir",
    "Total material": "Total pieza",
    "Total display": "Total pantalla",
    "Total material subsidized": "Total piezas bonificadas",
    "day": "día",
    "days": "días",
    "You must select at least one day": "Debe seleccionar al menos un dia",

    // Displays
    "Square": "Cuadrada",
    "Vertical": "Vertical",
    "Horizontal": "Horizontal",
    "Super horizontal": "Super horizontal",
    "The email entered already exists": "El email ingresado ya existe",
    "Enter a valid email": "Ingrese un email válido",
    "The name must be less than 255 characters.": "El nombre debe ser menor a 255 carácteres.",
    "The last name must be less than 255 characters.": "El apellido debe ser menor a 255 carácteres.",
    "The company name already exists. Please enter another.": "El nombre de la empresa ya existe. Ingrese otro.",
    "Incorrect username or password": "Usuario o contraseña incorrectos",
    "Fill in all fields": "Complete todos los campos",
    "We cant find that email in our database, try another one.": "No encontramos ese mail en nuestra base, pruebe con otro.",
    "Incorrect security code": "Codigo de seguridad incorrecto",
    "The code must have 6 digits": "El código debe tener 6 dígitos",
    "Your account is not verified": "Su cuenta no está verificada",
    "This account has already been verified.": "Esta cuenta ya fue verificada",
    "Unexpected error": "Error inesperado",
    "Too many attempts": "Ha realizado demasiadas solicitudes. Intente nuevamente más tarde.",
    "Account is not activated": "Esta cuenta no está activada",
    "Password changed": "Contraseña cambiada, por favor ingrese nuevamente",

    // Common
    "Good!": "Bien!",
    "Changes successfully saved": "Cambios guardados correctamente",
    "Loading": "Cargando",
    "Go back": "Volver",
    "Edit": "Editar",
    "Close": "Cerrar",
    "continue": "continuar",
    "edit": "editar",
    "Create": "Crear",
    "Apply": "Aplicar",
    "Select": "Seleccionar",
    "An error occurred, please try again": "Ocurrió un error, intente de nuevo",
    "Token expired, please login again": "Token vencido, vuelva a iniciar sesión",
    "Reload": "Volver a cargar",
    "Cancel": "Cancelar",
    "Contact us": "Contactanos",
    "Remove": "Quitar",
    "Add": "Agregar",
    "Can not be blank": "No puede estar en blanco.",
    "All screens must have at least some content": "Todas las pantallas tiene que tener al menos un contenido.",
    "You can not exeed 200 chars in this field": "No puedes exceder los 200 caracteres en este campo.",
    "seconds": "segundos",
    "persons": "personas",
    "Day": "Día",
    "unknown": "Desconocido",
    "Print": "spot",
    "Prints": "spots",
    "per print": "c/spot",
    "Results": "Resultado",
    "screen": "pantalla",
    "screens": "pantallas",
    "added": "agregada",
    "Show all screens": "Mostrar todas las pantallas",
    "Hide Screens": "Ocultar Pantallas",
    "All": "Todos",
    "Some": "Algunas",
    "Billing Country": "País de facturación",
    "Select country": "Seleccionar país",
    "Education": "Educación",
    "Entertainment": "Entretenimiento",
    "Services": "Servicios",
    "If you choose a DSP that is not LatinAd, you will only be able to make purchases in USD through an international invoice.": "Si seleccionas un DSP que no es LatinAd, solo podrás comprar en USD mediante factura internacional.",


    // audience Dats Why
    "Female": "Mujeres",
    "Male": "Hombres",
    "from 0 to 14": "de 0 a 14",
    "from 15 to 19": "de 15 a 19",
    "from 20 to 29": "de 20 a 29",
    "from 30 to 44": "de 30 a 44",
    "from 45 to 54": "de 45 a 54",
    "more than 55": "mas de 55",
    "Data obtained with the integration of": "Datos obtenidos con la integración de",
    "Audience Hyper-Insights that help you plan, monitor, and analyze Out of Home sites and campaigns to maximize ROI.": "Hyper-Insights de audiencias que te ayudan a planear, monitorear , analizar sitios y campañas Out of Home para maximizar el ROI.",
    "General":"General",
    "By day of week":"Por día de la semana",
    "Quantitative audience": "Audiencia cuantitativa",
    "Qualitative audience": "Audiencia cualitativa",
    "Audience": "Audiencia",
    "Audience based on third-party measurement data":"Audiencia basada en datos de medición de terceros.",
    "Third audience": "Audiencia de terceros",
    "Dats Why audience": "Dats Why Audience",
    "Audience summary": "Resumen de audiencia",
    "Data per month in the radius of the screen location": "Datos por mes en el radio de la ubicación de la pantalla",
    "Total vehicle impacts": "Impactos automóviles totales",
    "Unique vehicle impacts":"Impactos automóviles únicos",
    "Frequency of unique people": "Frecuencia de personas únicas",
    "Total people impacts":"Impactos personas totales",
    "Impacts on unique people": "Impactos personas únicas",
    "Average exposure in seconds": "Exposición promedio en segundos",

    // 404
    "We look everywhere for the page": "Buscamos la página por todos lados",
    "(404 error)": "(Error 404)",
    "Go to LatinAd": "Ir a LatinAd",

    // Modal of currency - billing Conuntry
    "Language & Currency configuration": "Configuración de idioma y moneda",
    "Language": "Idioma",
    "Billing Country": "País de facturación",
    "Apply settings": "Aplicar configuración",
    "More": "Más",
    "Dates": "Fechas",
    "You will receive an international invoice without local taxes and the payment must be in USD (American Dollars) internationally.": "Recibirás una factura internacional sin impuestos locales y el pago deberá ser en USD (Dólares Americanos) de manera internacional. En caso de existir gastos/retenciones en concepto de WithHolding Taxes, correrán como concepto extra y estarán a cargo del anunciante",
    "International billing": "Factura Internacional",

    // POI - Points of interest
    "Bar & Coffee": "Bar & Café",
    "Beauty care": "Belleza",
    "Education": "Educación",
    "Entertainment": "Entretenimiento",
    "Finance": "Finanzas",
    "Health": "Salud",
    "Institutions": "Instituciones",
    "Restaurants": "Restaurantes",
    "Services": "Servicios",
    "Shops": "Tiendas",
    "Transportation": "Transporte",
    "Vehicles": "Vehículos",

    // Info discount volume
    "2-7": "2 a 7 días",
    "8-15": "8 a 15 días",
    "16-28": "16 a 28 días",
    "29-60": "Desde 29 días",
    "Volume discount": "Descuento por volumen",
    "Discount applied": "Descuento aplicado",
    "Increase n_diff_discount your campaign to access higher discounts": "Incrementa n_diff_discount tu campaña para acceder a mayores descuentos",

    // subtype
    "Sub Type of screen": "Sub tipo de pantalla",
    "subtype_301": "Autopista",
    "subtype_302": "Ciudad",
    "subtype_303": "Parada de bus",
    "subtype_205": "Centro comercial",
    "subtype_101": "Aeropuerto",
    "subtype_105": "Subte",
    "subtype_106": "Estación de tren",
    "subtype_201": "Gasolinera",
    "subtype_202": "Tienda de conveniencia / Supermercado",
    "subtype_203": "Tienda de comestibles / Almacén / Kiosco",
    "subtype_207": "Farmacia",
    "subtype_401": "Gimnasio",
    "subtype_402": "Salón de belleza / Peluquería",
    "subtype_501": "Clínica",
    "subtype_601": "Escuela",
    "subtype_602": "Universidad",
    "subtype_701": "Centro Corporativo / Coworking",
    "subtype_801": "Club / Espacio recreativo",
    "subtype_804": "Bar",
    "subtype_805": "Restaurant",
    "subtype_1001": "Banco",
    "subtype_102": "Buses",
    "subtype_103": "Taxi (interior)",
    "subtype_104": "Taxi (exterior)",
    "subtype_10202": "Terminal",
    "subtype_204": "Licorería",
    "subtype_806": "Comida rápida",

    //External DSP
    "info_dsp": "Al seleccionar un DSP distinto a Latinad el proceso de creación de deals puede demorar entre 3 a 6 horas para que queden activos, posteriormente estarán listo para su implementación en el DSP seleccionado.",
    "info_payment_first": "Al seleccionar un DSP distinto a Latinad",
    "info_payment_second": "el pago de la campaña no puede ejecutarse dentro de este sitio, deberá realizarse en la plataforma seleccionada.",
    "info_payment_third": "El monto a invertir en la campaña creada en Latinad puede no contener las comisiones del DSP elegido, lo que puede provocar que el valor total asignado deba ser mayor para contemplarlos.",

    // Verified agencies
    "Verified agency": "Agencia verificada",
    "Limited functionality": "Funcionalidad limitada",
    "Verified agencies text": "Para acceder a todas las funcionalidades exclusivas de la plataforma se requiere que las agencias estén verificadas. Para más información visite:",
    "The maximun duration of a traditional campaign for non-verfied agencies is limited to a week.": "La duración máxima de una campaña para una agencia no verificada es de una semana.",
    "Company is not verified and has more than $5000 USD in campaigns this month": "No es posible crear la campaña. La agencia no está verificada y ha gastado más de $5000 USD en campañas este mes.",
    "The maximum budget for non verified agencies is 5000 USD a month": "El presupuesto máximo de campañas programáticas para agencias no verificadas es de 5000USD al mes.",

    //Bundles
    "Sales bundles": "Paquetes de ventas",
    "Sales bundle details": "Detalles del paquete de ventas",
    "This screen belongs to the following bundles.": "Esta pantalla pertenece a los siguientes paquetes.",
    "Number of screens": "Cantidad de pantallas",
    "Traditional price": "Precio tradicional",
    "Programmatic price": "Precio programático",
    "month": "mes",
    "Delete to cart": "Eliminar del carrito",
    "Number of screens": "Cantidad de pantallas",
    "Type of bundle": "Tipo de paquete",
    "Displays of bundle": "Pantallas del paquete",
    "You can buy it only in a bundle (You will buy the entire bundle)": "Podés comprarla solo en paquete (Comprarás el paquete entero)",
    "You can buy it individually or as a sales bundle (You will buy the entire bundle)": "Podés comprarla individualmente o como paquete de ventas (Comprarás el paquete entero)",
    "This screen belongs to the following packages.": "Esta pantalla pertenece a los siguientes paquetes.",
    "When deleting this screen, the packages to which it belongs, as well as the screens associated with those packages, will be automatically removed from the cart.": "Al eliminar esta pantalla, se eliminarán automáticamente del carrito los paquetes a los que pertenece, así como las pantallas asociadas a dichos paquetes.",
    "Belongs to the following packages": "Pertenece a los siguientes paquetes",

    // Verified agencies
    "Verified agency": "Agencia verificada",
    "Limited functionality": "Funcionalidad limitada",
    "Verified agencies text": "Para acceder a todas las funcionalidades exclusivas de la plataforma se requiere que las agencias estén verificadas. Para más información visite:",
    "The maximun duration of a traditional campaign for non-verfied agencies is limited to a week.": "La duración máxima de una campaña para una agencia no verificada es de una semana.",
    "Company is not verified and has more than $5000 USD in campaigns this month": "No es posible crear la campaña. La agencia no está verificada y ha gastado más de $5000 USD en campañas este mes.",
    "The maximum budget for non verified agencies is 5000 USD a month": "El presupuesto máximo de campañas programáticas para agencias no verificadas es de 5000USD al mes.",

    //Transfer of spots
    "Transfer of spots": "Transferencia de spots",
    "Screen owners": "Dueños de pantalla",
    "Subtracted spots": "Spots. restados",
    "Added spots": "Spots agregados",
    "Initial budget": "Presupuesto inicial",
    "Initial spots": "Spots iniciales",
    "Available credit": "Credito disponible",
    "Apply changes": "Aplicar cambios",
    "Pending spots": "Spots pendientes",
    "Budget": "Presupuesto",
    "You have available credit. You must use it to continue distributing.": "Tiene crédito disponible. Debe consumirlo para continuar distribuyendo",
    "Consumed credit. You can apply changes and edit. The remaining credit (n_credite) cannot be used to purchase 1 spot.": "Crédito consumido. Puede aplicar cambios y editar. El crédito sobrante (n_credite) no es posible consumirlo para la compra de 1 spot.",

    // Audience provider info
    "Audience data for these screens provided by": "Los datos de audiencia de estas pantallas son proporcionados por",
    "Audience data for this screen provided by": "Los datos de audiencia de esta pantalla son proporcionados por",
    "display owner": "el dueño de medios",
    "There is no audience data for this screen": "No hay datos de audiencia para esta pantalla",
    "There is no audience data for these screens": "No hay datos de audiencia para estas pantallas",

    // Rules component
    "Rules": "Reglas",
    "Days of the week": "Días de la semana",
    "Create rules": "Crear reglas",
    "Hours of function": "Horas de funcionamiento",
    "Name of the rule": "Nombre de la regla",
    "New rules": "Nuevas reglas",
    "Apply rules": "Aplicar reglas",
    "Remove rule": "Quitar regla",
    "Configuration rules on": "Configurar reglas en",
    "displays": "pantallas",
    "displays": "pantalla",
    "spots": "spots",
    "Spots": "Anuncios",
    "Apply rules to display": "Aplicar reglas a pantalla",
    "Apply rules to spot": "Aplicar reglas a spot",
    "Apply rules to group": "Aplicar reglas a grupo",
    "Apply rules to campaign": "Aplicar reglas a campaña",
    "There are no general rules for this group": "No hay reglas generales para este grupo",
    "There are no general rules for this display": "No hay reglas generales para esta pantalla",
    "There are no rule for this spot": "No hay reglas para este anuncio",
    "Content name": "Nombre de contenido",
    "Spot name": "Nombre del anuncio",
    "Not rules created": "No hay reglas creadas",
    "The name of the rule already exists": "El nombre de la regla ya existe",
    "The name of the rule is required": "El nombre de la regla es requerido",
    "Applied rule": "Regla aplicada",
    "Save": "Guardar",
    "Save and apply": "Guardar y aplicar",
    "You are about to permanently remove this rule from the list. This action cannot be undone": "Estás a punto de eliminar permanentemente esta regla de la lista. Esta acción no se puede deshacer",

    // Screens Group
    "Removing this spot will redistribute the impressions among the remaining spots.": "Eliminando este anuncio redistribuirá los spots entre los restantes.",
    "You are going to remove this screen from the campaign": "Vas a quitar esta pantalla de la campaña.",
    "Are you sure you want to delete?" : "¿Estás seguro de que quieres eliminar?",
    "You are going to remove this group of screens from the campaign": "Vas a quitar este grupo de pantallas de la campaña.",
    "To delete need at least two group in the campaign": "Para eliminar se necesitan al menos dos grupos en la campaña",
    "Remove group": "Quitar grupo",
    "Investment": "Inversión",
    "Investment of content": "Inversión de contenido",
    "Content version": "Versión de contenido",
    "Distributed by": "Distribuido por",
    "None": "Ninguna",
    "Countries": "Paises",
    "This screen does not accept contents of type": "Esta pantalla no acepta anuncios del tipo",
    "image": "image",
    "video": "video",
    "html": "html",
    "Balancear impressions": "Balancear spots",
    "Equalize the spots across all screens, in the exact amount that keeps the campaign is budget up to date": "Iguala los spots en todas las pantallas, en la cantidad justa que mantenga el presupuesto actualizado de campaña",
    "You're about to equalize the spots across all screens, which will modify any previous spot distribution. Do you wish to continue?": "Estas por igualar los spots en todas las pantallas, lo que modificará cualquier distribución previa de spots ¿Deseas continuar?",
    "Confirm redistribution": "Confirmar redistribución",

    // ContentSpecificationAlertComponent
    "Some content does not meet the specifications on one or more screens in this group": "Algunos anuncios no cumplen con las especificaciones en una o más pantallas de este grupo",
    "Some content does not meet the specifications on this screen": "Algunos anuncios no cumplen con las especificaciones en esta pantalla",
    "Some content does not meet the specifications on one or more screens": "Algunos anuncios no cumplen con las especificaciones en una o mas pantallas",

    // CheckCompanyForUserComponent
    "Different companies": "Empresas diferentes",
    "The company logged into the system (n_company) does not match the one you have in the DSP (n_company_check). You will be redirected to avoid errors.": "La empresa logueada en el sistema (n_company) no coincide con la que tienes en el DSP (n_company_check). Se va redireccionar para no cometer errores."
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import HomeHolder from "./Styles/homeComponentStyle";
import SearchBoxContainer from "../containers/SearchBoxContainer";
import Footer from "./Footer";
import { CSSTransition } from 'react-transition-group';
import CSSEffect from './Styles/CSSEffect'
import Colors from "../themes/Colors";
import {getDomain} from "../services/utils";
import { Row, Col, Button } from "antd";

// import mainImage from "../images/main-bk.png";
import mainImageLatinad from "../images/latinad-main.svg";
import mainImageLatinadMobile from "../images/latinad-main-mobile.png"
import mainImageFenix from "../images/whiteLabel/main-bk-fenix.png";
import mainImageMediaShake from "../images/whiteLabel/media-shake-main.png";
import mainImageSmartFit from "../images/whiteLabel/smart-fit-main.png";
import mainTerritorioDigital from "../images/whiteLabel/territorio-digital-main.png";
import mainDuoprint from "../images/whiteLabel/duoprint-main.jpg";
import mainMovImage from "../images/whiteLabel/movImage-main.png";
import mainImageYoda from "../images/whiteLabel/yoda-main.png";
import mainHakMedia from "../images/whiteLabel/hakMedia-main.png"

import dots from "../images/dots.png";

import { withTranslation } from 'react-i18next';
import UrlEnum from "../constants/UrlEnum";
import { dom } from "@fortawesome/fontawesome-svg-core";
class HomeComponent extends Component {

  state = {
    showBoost: false,
    showBudget: false,
    domain : getDomain(window.location.hostname),
  }

  componentDidMount() {
    this.handleLoginFromCms();
    this.handleRedirect();

    // Init data of audience
    this.props.cleanAudience();
    this.handleBoostBanner();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldPromptLogin !== this.props.shouldPromptLogin && this.props.shouldPromptLogin) {
      const message = this.props.match.params.msg;
      if (message) {
        this.props.userLoginFailure([JSON.parse(atob(message))]);
      }
    }
  }

  handleBoostBanner = () => {
    this.setState((prevState) => ({
      showBoost: !prevState.showBoost,
    }))
  };

  handleBudgetBanner = () => {
    this.setState((prevState) => ({
      showBudget: !prevState.showBudget,
    }));
  }

  handleLoginFromCms = () => {
    if (!this.props.match.params.token) return
    const rawInfo = this.props.match.params.token;
    const loginInfo = JSON.parse(atob(rawInfo));
    const { token, campaignId, gotoPayment } = loginInfo;
    if (token) {
      this.props.logout();
      this.props.loginFromCms(token);
      if (campaignId) {
        if (!gotoPayment) {
          this.props.history.push(`/campaign/${campaignId}`);
        } else {
          this.props.history.push(`/campaign/${campaignId}/payment`);
        }
      } else {
        this.props.history.push("/login");
      }
    }
  }

  // Re-login after 4XX error, allows the user to continue the flow
  handleRedirect = () => {
    const url = this.props.match.params.url;
    if (!url) return;
    this.props.logout();
    this.props.setShouldPromptLogin(true, atob(url));
  }

  render() {
    const { t } = this.props;
    const { showBoost, showBudget, domain} = this.state;
    const isMobile = window.innerWidth < 576;
    let cssContentMain =  "";
    if ( domain === "latinad" ) { cssContentMain = "latinad" }
    if ( domain === "mediaShake" ) {cssContentMain = "mediaShake"}
    if ( domain === "smartFit" ) {cssContentMain = "customImage smartFit"}
    if ( domain === "territorioDigital" ) {cssContentMain = "customImage territorioDigital"}
    if ( domain === "duoPrint" ) {cssContentMain = "customImage duoPrint"}
    if ( domain === "movImagen" ) {cssContentMain = "customImage movImagen"}
    if ( domain === "yoda" ) {cssContentMain = "yoda"}
    if ( domain === "hakMedia" ) {cssContentMain = "hakMedia"}

    const domainToUrlMap = {
      'mediaShake': UrlEnum.landingMediaShake,
      'territorioDigital': UrlEnum.landingTerritorioDigital + "#contacto",
      'smartFit': UrlEnum.landingSmartFit,
      'duoPrint': UrlEnum.landingDuoprint,
      'movImagen': UrlEnum.landingMovImagen + "/nosotros",
      'yoda': UrlEnum.landingYoda,
      'hakMedia': UrlEnum.landingHakMedia,
    };

    const linkKnow = domainToUrlMap[domain] || '#infoGeneral';

    return (
      <HomeHolder>
      <span className={cssContentMain}>
          <Row className={"heroContainer " + cssContentMain} justify="center" align="middle" style={{ zIndex: "10"}}>
            <span>
              {domain === "latinad" ? (
                <picture>
                  <source media="(max-width: 768px)" srcSet={mainImageLatinadMobile} />
                  <img src={mainImageLatinad} className="mainImageLatinad" alt="" />
                </picture>
              ) :
                domain === "fenix" ? (
                <img src={mainImageFenix} className="mainImage" alt="" />
              ) :
                domain === "mediaShake" ? (
                <img src={mainImageMediaShake} className="mainImageRight" alt="" />
              ):
                domain === "smartFit" ? (
                <img src={mainImageSmartFit} className="mainImage" alt="" />
              ):
                domain === "territorioDigital" ? (
                <img src={mainTerritorioDigital} className="mainImage" alt="" />
              ) :
              domain === "duoPrint" ? (
                <img src={mainDuoprint} className="mainImage" alt="" />
              ):
              domain === "yoda" ? (
                <img src={mainImageYoda} className="mainImageRight" alt="" />
              ):
              domain === "movImagen" ? (
                <img src={mainMovImage} className="mainMoveImage" alt="" />
              ):
              domain === "hakMedia" ? (
                <img src={mainHakMedia} className="mainHakMediaImage" alt="" />
              ):null }
            </span>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginTop: "150px" }}
            >
             { domain === "latinad" &&
                <h1 class="gradient-title size-gradient-title">{t("Plan your campaign")}</h1>
             }
              <SearchBoxContainer
                showBudget={showBudget}
                handleBudgetBanner={this.handleBudgetBanner}
                handleBoostBanner={this.handleBoostBanner}
              />
            </Col>
            { (domain != "hakMedia" && domain != "latinad") && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ padding: "0 9%", zIndex: "10" }}
              >
                {this.props.programmatic === "programmatic" || (this.props.audience  && this.props.audience.total_impacts) ? null : (
                  <CSSEffect>
                    <CSSTransition
                      in={showBoost}
                      onExited={this.handleBoostBanner}
                      timeout={1500}
                      classNames="my-node mediaShake"
                    >
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        className={
                          isMobile ? "containerInfoMobile" : "containerInfo"
                        }
                      >
                        <h1
                          className={isMobile ? "mainTextMobileSEO" : "mainTextSEO"}
                        >
                          {domain ===  "mediaShake" ? t("Digital signage (DOOH)") : t("Digital signage")}
                        </h1>
                        <h1 className={isMobile ? "mainTextMobile" : "mainText"}>
                          {domain ===  "mediaShake" ? t("High-Value Audiences at a Click") : t("Boost your brand")}
                        </h1>
                        <h2
                          className={
                            isMobile ? "secondaryTextMobile" : "secondaryText"
                          }
                        >
                          {domain ===  "mediaShake" ?
                          t(
                            "Strategically positioned where your ideal audience is."
                          ):
                          t(
                            "Create campaigns on public roads in minutes and reach thousands of people"
                          )}
                        </h2>
                        <a href={`${linkKnow}`} target={linkKnow === '#infoGeneral' ? '_self' : '_blank'}>
                          <Button
                            type="primary"
                            shape="round"
                            style={
                                domain === "movImagen" ? {color: Colors.primary, backgroundColor: 'white'} :
                                {backgroundColor: Colors.blueDark, borderColor: Colors.blueDark}}>
                              {t("Get to know us")}
                          </Button>
                        </a>
                      </Col>
                    </CSSTransition>
                  </CSSEffect>
                )}
              </Col>
            )}
          </Row>
          <>
          { domain === "latinad" && ( <Footer /> )}
          </>
      </span>
      </HomeHolder>
    );
  }
}

HomeComponent.contextTypes = {
  router: PropTypes.object
};

export default withTranslation()(HomeComponent);

const UrlEnum = {
    //grupoVia
    grupoViaHostname: process.env.REACT_APP_GRUPO_VIA_HOSTNAME,
    grupoViaSubdomain: process.env.REACT_APP_GRUPO_VIA_SUBDOMAIN,
    grupoViaCMS: "https://grupovia.latinad.com",

    //shake
    mediaShakeHostname: process.env.REACT_APP_MEDIA_SHAKE_HOSTNAME,
    mediaShakeSubdomain: process.env.REACT_APP_MEDIA_SHAKE_SUBDOMAIN,

    //smartFit
    smartFitHostname: process.env.REACT_APP_SMART_FIT_HOSTNAME,
    smartFitSubdomain: process.env.REACT_APP_SMART_FIT_SUBDOMAIN,
    // Ladorian 272; Ladorian PA 867; Ladorian CR 868
    companiesSmartfit: [727, 867, 868],

    //duoPrint
    duoPrintHostname: process.env.REACT_APP_DUO_PRINT_HOSTNAME,
    duoPrintSubdomain: process.env.REACT_APP_PRINT_SUBDOMAIN,
    // DuoPrint 741
    companiesDuoPrint: [741],

    //territorioDigital
    territorioDigitalHostname: process.env.REACT_APP_TERRITORIO_DIGITAL_HOSTNAME,
    territorioDigitalSubdomain: process.env.REACT_APP_TERRITORIO_DIGITAL_SUBDOMAIN,

    //fenix
    fenixHostname: process.env.REACT_APP_FENIX_HOSTNAME,
    fenixSubdomain: process.env.REACT_APP_FENIX_SUBDOMAIN,

    //movimagen
    movImagenHostname: process.env.REACT_APP_MOVIMAGEN_HOSTNAME,
    movImagenSubdomain: process.env.REACT_APP_MOVIMAGEN_SUBDOMAIN,

    //yoda
    yodaHostname: process.env.REACT_APP_YODA_HOSTNAME,
    yodaSubdomain: process.env.REACT_APP_YODA_SUBDOMAIN,
    companiesYoda: [ 1251, 1250, 1249, 1248, 1247, 1068 ],

    //hakMedia
    hakMediaHostname: process.env.REACT_APP_HAK_MEDIA,
    hakSubdomanin: process.env.REACT_APP_HAK_MEDIA,

    landingEs: "https://latinad.com",

    landingMediaShake : "https://mediashake.co",
    landingSmartFit: "https://smartfit.com.ar/",
    landingDuoprint: "https://www.duoprint.com.ec/",
    landingTerritorioDigital: "https://territoriodigital.co",
    landingFenix: "https://fenix.publinet.us",
    landingMovImagen: "https://movimagen.com.uy",
    landingYoda: "https://yodaooh.com/",
    landingHakMedia: "https://latinad.com/medios"

  };


export default UrlEnum;
